<template>
  <div style="padding-bottom: 40px">
    <div class="main">

      <div class="table">
        <el-table v-if="tableData" v-loading="loading" border :header-cell-style="{
          'background-color': '#f4f7fc',
          'color': 'black',
          'letter-spacing': '1px', padding: '6px 0', 'text-align': 'center', 'text-indent': '0'
        }" :cell-style="{ height: '10px', padding: '4px 0' }" :data="tableData"
          style="width: 100%;font-size:16px;text-align:center">
          <el-table-column prop="specialty_id" align="center" width="100" label="专业代号">
            <template slot-scope="scope">
              <span>{{ scope.row.specialty_id | interpo }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="specialty_name" align="left" label="专业名称" width="300" show-overflow-tooltip />
          <el-table-column prop="pici" align="center" label="招生批次" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.pici }}</span>
              <span v-if="scope.row.school_name.indexOf('（') > -1">{{ '/' + scope.row.school_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="plan_num" align="center" width="90" label="专业计划" />
          <el-table-column prop="tuition" align="center" width="120" label="收费标准">
            <template slot-scope="scope">
              {{ scope.row.tuition | tuitionFilter }}
            </template>
          </el-table-column>
          <el-table-column prop="remarks" align="center" label="专业详情" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.remarks }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="font-size:12px;margin:20px 0;color: gray;">备注：以上招生计划仅供参考，最终填报志愿请以河南省教育考试院公布代码和数据为准</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "",
  computed: { ...mapState(["userInfo"]) },
  data() {
    return {
      tableData: [],
      is_wenli: "",
      batch: "4",
      loading: true,
    };
  },
  filters: {
    tuitionFilter(e) {
      if (e > 0) {
        return e + '元/年'
      } else {
        return '学费暂无'
      }
    }
  },
  mounted() {
    this.$parent.$data.cat = 6

    if (this.userInfo) {
      this.is_wenli = this.userInfo.is_wenli;
      this.pageSize = 50;
    } else {
      this.is_wenli = 1;
    }
    this.id = this.$route.query.sid;

    // 获取批次/科类列表
    this.getPlan(this.$route.query.sid);
  },
  methods: {
    getPlan(id) {
      if (!this.id) {
        this.loading = false
        return false
      }
      this.loading = true
      this.$fecth
        .get("enrollment_plan/getData", {
          params: {
            school_id: id,
            is_wenli: this.is_wenli,
            batch: this.batch,
          },
        })
        .then((res) => {
          this.loading = false
          this.tableData = res;
        });
    },
    onChange() {
      this.getPlan(this.$route.query.sid);
    },
  },

};
</script>

<style lang="less" scoped>
.main {
  width: 1200px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;

  .title {
    padding-left: 40px;
    margin-top: 30px;
    font-size: 36px;
    color: #555555;

    .label {
      font-size: 20px;
      margin: 0 20px;
    }

    .radio {
      margin-left: 20px;
      color: #eeeeff;
      font-size: 20px;

      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .table {
    margin: 7px 0;
  }
}
</style>
